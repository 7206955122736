// Privacy.js
import React from 'react';
import './TrimFit.css'; // import TrimFit.css

function Privacy() {
  return (
    <div className="privacy">
      {/* Your content here */}
    </div>
  );
}

export default Privacy;