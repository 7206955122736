// Contact.js
import React from 'react';
import './TrimFit.css'; // import TrimFit.css

function Contact() {
  return (
    <div className="contact">
      {/* Your content here */}
    </div>
  );
}

export default Contact;